
.sidebar-nav-item {
  
  a {
    img {
      margin-top: 9px;
    }
    
  }
  
  .name {
    margin-top: 20px;
  }
}

.name {
  position: absolute;
  top: 4px;
}

.sidebar-nav-container > .sidebar-nav > li > a > .rubix-icon {
  margin-top: 10px;
}

#logout {
  font-family: Karla;
  font-size: 14pt;
  font-weight: normal;
  color: white;
  position: absolute; 
  bottom: 50px;
  left: 30px;
  cursor: pointer;
}
