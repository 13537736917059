
.sh-list-view {
  
  table-layout: fixed;
  position: relative;

  th {
    font-family: Karla;
    font-size: 14pt;
    font-weight: normal;
    background: white;
    padding-left: 20px !important;
  }

  tr {
    height: 60px;

    td {
      padding-left: 20px !important;
      word-wrap: break-word;
      white-space: normal !important;
    }
  }
}

@mixin rotated($degrees) {
  -ms-transform: rotate($degrees); /* IE 9 */
  -webkit-transform: rotate($degrees); /* Chrome, Safari, Opera */
  transform: rotate($degrees);
}

.quarter-clockwise {
  @include rotated(90);
}

.inline-block {
  display: inline-block
}

@mixin circular() {
  border-radius: 50%
}

.selection-button {
  @include circular();
  border: 1px solid #E2E7DD;
  height: 25px;
  width: 25px;
  background: white;
}
.selection-button.active {
  background: #96af89;
}

.clear-button {
  overflow: hidden;
  background: transparent;
  border: none;
  display: inline-block;
  vertical-align: middle;

  &:active &:focus &:visited {
    outline:0;
  }
}

.section-header-title {
  font-family: Source Serif Pro;
  font-weight: normal;
  font-size: 28px;
  color: $SHGreen;
  padding-left: 0px; 
}

.row-child {
  background-color: #fcfbfa
}
.row-tinted {
  background-color: #f1f3ee;
}

.row-selected {
  background-color: #dfe7db;
}

.inline-block {
  display: inline-block;
}

.section-header-control {
  font-family: Karla;
  font-weight: normal;
  font-size: 16px;
  color: #666983;
  margin-top: 8px;    
  right: 25px;
  bottom: 0;
  left: auto;
  top: 19px;
  padding-right: 20px;
  padding-bottom: 5px;
}

.list-header-row {
  color: #666983;
  font-family: Karla;
  font-size: 16px;
  background-color: white;
}

.position-relative {
  position: relative;
}

.list-row {
  height: 50px;
  line-height: 50px;
  border-bottom:1px solid #ede9df;
}

.list-child-row-border {
  padding: 0px;
  height: 100%;
  width: 10px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #6B8C56;
  overflow: auto;
}
