.create-session-panel {
  
  .control-label {
    text-align: left;
    margin-top: 5px;
  }
  
  .dropdown {
    display: inline-block;
  }
  
  .hour-dropdown {
    width: 60px;
  }
  
  .meridian-dropdown {
    width: 54px;
  }
  
  .scrollable-menu {
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
  .session-date-picker {
    
    input {
      border-radius: 4px !important;
    }
    
    .input-group-addon {
      background: transparent;
      font-size: 15pt;
    }
  }
  
  .dropdown-menu > li > a {
    outline: none;
    color: $SHNavyBlue;

    &:hover {
      color: $SHGreen;
      background: white;
    }
  }
}

.schedule-date {
  display: inline-block;
  margin-right: 10px;
  width: 250px;
}

.remove-session-btn {
  cursor: pointer;
  display: inline-block;
}

ul.sessions-list {
  color: $SHNavyBlue;
  font-family: Karla;
  font-size: 12pt;
  font-weight: normal;
  list-style-type: none;
  padding-left:0;
  
  li {
    margin-top: 5px;
    list-style-type: none;
  }
}
