.navbar-user-info-wrapper {
  height: $navbar-height;
  vertical-align: middle;
  display: table-cell;
  color: #FAF8F5;
  font-family: Karla;
  font-weight: normal;
  font-size: 14pt;

  img {
    height: 43px; // Hardcoding to be the height of the wrapper minus the top and bottom margins
    border: 2px solid #666983;
    margin: 8px;
    margin-left: 15px;
  }
}

.auth-org-name {
  font-family: Source Serif Pro;
  font-size: 14pt;
  color: #FAF8F5;
  line-height: 1.0;
  margin-top: 10px;
  display: inline-block;
}

.auth-org-role {
  font-family: Karla;
  font-size: 9pt;
  color: #FAF8F5;
  line-height: 1.0;
}

#role-dropdown-toggle {
  text-align: left;
  background: rgba(0,0,0,0);
  height: 59px;
  border: 0px;
  font-weight: normal;
}

.dashboard-panel-header {
  font-family: Source Serif Pro;
  font-size: 16pt;
  font-weight: normal;
  color: $SHPurple;

  a.links {
    font-size: 10pt;
    float: right
  }
}
