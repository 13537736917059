.edit-form-container {
  
  padding-left: 0px;
  
  .form-control {
    width: 250px;
  }
  
  input.form-control {
    height: 44px;
  }
  
  .control-label {
    font-weight: normal;
    margin-top: 40px;
  }
  
  .control-label:first-of-type {
    margin-top: 15px !important;
  }
  
  .form-title {
    font-family: Source Serif Pro;
    color: $SHGreen;
    font-size: 14pt;
  }
  
  #left-col {
    padding-left: 0px;
    border-right: 1px solid $SHBeigeAccent;
  }
  
  #right-col {
    padding-left: 60px;
  }
  
  hr {
    border-color: $SHBeigeAccent;
    margin-top: 10px;
  }
}


.rounded-form-button {
  background: $SHPurple;
  color: white;
  border: 1px solid #D5D6D7;
  border-radius: 3px;
  font: Karla;
  font-size: 12pt;
  text-transform: uppercase;
  padding-left: 15px;
  padding-right: 15px;
  
  &:disabled {
    background: #ddc5d2;
  }
}

.validation-error {
  margin-top: 5px;
  color: #d8772d;
  border: 1px solid;
}
