.dash-grid-row {
  margin-bottom: 20px;
}

.graph-card {
  background: white;
  text-align: center;
  height: 250px;
  min-width: 250px;
  padding: 20px;
  
  border: 1px solid #EDE9DF;
  box-shadow: 2px 2px 3px #EDE9DF;
  
  .title {
    font-family: Source Serif Pro;
    font-size: 12pt;
    color: #8A3965;
    margin-top: -10px;
  }
}

.graph-footnote {
  font-family: Karla;
  font-size: 10pt;
  color: #546678;
}

.single-value-large {
  font-family: Karla;
  font-size: 54pt;
  color: #97AE8A;
}
