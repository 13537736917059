.org-form-row {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

$border-radius: 5px;


.org-form-column {
  width: 50%;
  float: left;
  
  &.org-form-column-centered {
    width: 100%;
    float: none;
  }
  .radio {
    text-transform: capitalize;
    margin-right: 20px;
    
    span {
      margin-left: 10px;
    }
  }
  
  .btn.dropdown-toggle {
    height: 44px;
    width: 250px;
    border-width: 1px;
    border-color: #dddddd;
    
    .caret {
      float: right;
      margin-top: 9px;
    }
  }
  
  &:last-of-type {
    min-width: 320px;
  }
}

.roles-dropdown {
  height: 50px;
  width: 100%;
}

.clear-search {
  display: inline;
  font-size: 15pt;
  font-family: Karla;
  margin-left: 14px;
  color: $SHMediumGray;
  cursor: pointer;
}
.org-dropdown {
  text-align: left
}