#blocklyDiv {
  float: right;
}

div.blocklyToolboxDiv {
  z-index: 100;
  background-color: #FFFFFF;
  border: 1px solid #E5DFCF
}

span.blocklyTreeLabel {
  font-family: Karla;
  font-size: 14px;
}

// Horizontal scrollbar color and size
g.blocklyScrollbarHorizontal rect.blocklyScrollbarHandle {
  fill: #A09C90;
  fill-opacity: 30%;
  height: 6px;
}

// Vertical scrollbar color and size
g.blocklyScrollbarVertical rect.blocklyScrollbarHandle {
  fill: #A09C90;
  fill-opacity: 30%;
  width: 6px;
}

// Offsets the WHEN item in the toolbox
div div:first-child div.blocklyTreeRow.blocklyHorizontalTree {
  margin-left: 25px;
}

// Sets the height of the toolbox.  These need a 6px gap to account for the font size and other padding.
div div div.blocklyTreeRow.blocklyHorizontalTree {
  margin-top: 4px;
  margin-bottom: 10px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 12px;
  border-radius: 4px;
}

// Sets the text color of the selected item in the toolbox
div div div.blocklyTreeRow.blocklyHorizontalTree.blocklyTreeSelected span.blocklyTreeLabel {
  color: #6B8C56;
  font-weight: bold;
}

// Sets the background color of the When Do workspace
g.blocklyWorkspace rect.blocklyMainBackground {
  fill: #FAF8F5;
}

// Sets the backgronud color of the flyout (toolbox) of the When Do workspace
g.blocklyFlyout path.blocklyFlyoutBackground {
  fill: #EDE9DF;
  fill-opacity: 35%;
}

// Sets border around the When Do workspace
div.tab-content {
  border: 1px solid #E5DFCF;
}

// Resizes the code editors to match the height of the When Do workspace
div.CodeMirror.cm-s-ambiance.CodeMirror-wrap {
  height: 100%;
}

// Blockly element text
text.blocklyText {
  font-family: Karla
}

#whendo-control-bar {
  height: 70px;
  background-color: #FFFFFF;
  border: 1px solid #E5DFCF;
}

button.btn.btn-default.whendo-workspace-control-button {
  height: 100%;
  font-family: Karla;
  font-weight: bold;
  font-size: 9px;
  color: #666983;
  background: none;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 15px;
  margin-right: 15px;
  text-transform: none;
}

button#whendo-save-button {
  height: 43px;
  font-family: Karla;
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
  background-color: #8A3965;
  border-color: #8A3965;
  vertical-align: middle;
    margin-left: 15px;
  margin-right: 15px;
    margin-top: 11px;
    float: right;
}

button#whendo-cancel-button {
  height: 43px;
  font-family: Karla;
  font-weight: normal;
  font-size: 14px;
  vertical-align: middle;
    margin-left: 15px;
  margin-right: 15px;
   margin-top: 11px;
       float: right;
}

div.whendo-workspace-resolution-outer-div {
  display: inline-block;
  height: 100%;
  vertical-align: top;
  position: relative;
}

div.whendo-workspace-resolution-inner-div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  float: right;
}

.right {
  float: right;
}

tr.whendoRow:nth-child(odd) {
  background-color: rgba(226, 231, 221, 0.5)
}

.tooltip [class$="inner"] {
  max-width: 600px;
  color: #666983;
  font-size: 14px;
  background-color: #FFFFFF;
  border: 2px solid #6B8C56;
}

.tooltip [class$="inner"] .bolder {
  color: #000000;
  font-weight: bold;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #6B8C56;
}
