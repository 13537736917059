html.default body #sidebar {
  .rubix-timeline-view {
    .rubix-timeline-title {
      background: none;
      padding: 4px 0px;
    }
    .rubix-timeline-body li:before {
      box-shadow: 0px 0px 0px 6px #273135;
    }
  }
   #header-container {
      height: $navbar-height;
         text-align: center;
           background: $SHPurple;

          img {
        width: 113px;
        height: 42px;
        margin-top: 9px;
      }
    }

  #sidebar-container {
        bottom: 0;
        overflow: hidden;
        position: absolute;

        @if $sidebar-controls-visibility == hidden {
          top: 65px;
        } @else {
          top: 75px;
        }

        .sidebar {
          top: 0;
          bottom: 0;
          overflow: hidden;
          position: absolute;
          transition: all 0.3s ease;
        }
  }
}

html.default body .sidebar-nav-container > .sidebar-nav > li > a > .name {
          color: white;
          font-family: Karla;
          font-size: 14pt;
          font-weight: normal;

}

html.default body .sidebar-nav-container > .sidebar-nav > li > a {
      height: 65px;
        border: none;
        outline: none;
        color: #89949B;
        display: block;
        font-size: 14px;
        line-height: 14px;
        text-decoration: none;
        padding: 15px 0px 15px $line-height-computed;

}

html.default body .sidebar-nav-container > .sidebar-nav > li {
      height: 65px;
      outline: none;
      cursor: pointer;
      overflow: hidden;
      position: relative;
}

html.default body .sidebar-nav-container > .sidebar-nav {
      height: 65px;
}

.sidebar-btn > a {
  height: 65px;
}

html.default body  .sidebar-nav-container  .sidebar-nav {
    padding: 0;
    list-style: none;
    margin-left: $line-height-computed;
    border-left: 2px solid rgba(#3B4648, 0.5);

    >li {
      height: 45px;
      outline: none;
      cursor: pointer;
      overflow: hidden;
      position: relative;

      >a {
        height: 45px;
        border: none;
        outline: none;
        color: #89949B;
        display: block;
        font-size: 14px;
        line-height: 14px;
        text-decoration: none;
        padding: 15px 0px 15px $line-height-computed;

        > .rubix-icon {
          width: 16px;
          height: 17px;
          vertical-align: top;
          display: inline-block;
        }

        #nav-icon {
          margin-top: 0px;
        }

        > .name {
          line-height: 16px;
          vertical-align: top;
          color: white;
          font-family: Karla;
          font-size: 14pt;
          font-weight: normal;

          >span>.label {
            float: right;
            margin-top: -3px;
            padding: 5px 10px;
            margin-right: 30px;
          }
        }

        > .rubix-icon ~ .name {
          margin-left: 10px;
        }

        &:hover,&:focus {
          border: none;
          background: rgba(0, 0, 0, 0.2);
        }
      }

      &:hover, &.open {
        &:before {
          display: none;
        }
      }

      &.open {
        height: 100%;

        > a {
          background: rgba(0, 0, 0, 0.2);
        }
      }

      &:before {
        left: 0;
        top: 31px;
        width: 15px;
        content: ' ';
        position: absolute;
        display: inline-block;
        border: 1px solid rgba(#3B4648, 0.5);
      }

      &.active {
        > a {
          background: rgba(0, 0, 0, 0.4);
          padding-left: $line-height-computed - 5px;

          &:hover {
            background: rgba(0, 0, 0, 0.4);
          }
        }

        &:before {
          display: none;
        }

        &:after {
          top: 19px;
          right: 0;
          content: '';
          position: absolute;
          display: inline-block;
          border-top: 12px solid rgba(0, 0, 0, 0);
          border-bottom: 12px solid rgba(0, 0, 0, 0);
        }
      }
    }
  }

 html.default body  .sidebar-nav-container > .sidebar-nav {
    border: none;
    margin-left: - $line-height-computed;
    margin-right: - $line-height-computed;

    > li {
      &:before {
        display: none;
      }

      > a {
        font-size: 16px;
        line-height: 16px;
        padding: 15px $line-height-computed;

        > .name {
          line-height: 18px;
          margin-left: 15px;
           font-size: 18px;
        }

        > .rubix-icon {
          width: 18px;
          height: 19px;
          display: inline-block;
        }
      }

      &.active {
        >a {
          padding-left: $line-height-computed - 5;
        }
      }
    }
  }
  

  #navbar ~ #sidebar {
    top: $navbar-height;
  } 