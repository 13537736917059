@import "spinner";
.auth-role-container {

  li[role="presentation"]  > a[role="menuitem"]{
    color: #6c8b58;
    // color: #602747;
  }
  .dropdown-header {
    font-weight: bolder;
    font-size: 14px;
    background-color: #6c8b58;
    // background-color: #8B3765;
    // color: #a09f9c;
    color: #FFF;
    padding-left: 15px !important;
    cursor: default;
  }
}
// .dropdown-header {
//   font-weight: bolder;
//   font-size: 14px;
//   background-color: #602747;
//   color: #FFF;
//   padding-left: 15px !important;
// };