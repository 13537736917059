$GradientDegrees: -30deg;
$GradientStart: #8B3765;
$GradientShadow: #272A4B;

#auth-background {
  background: $GradientStart; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient($GradientDegrees, $GradientShadow, $GradientStart); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient($GradientDegrees, $GradientShadow, $GradientStart); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient($GradientDegrees, $GradientShadow, $GradientStart); /* For Firefox 3.6 to 15 */
  background: linear-gradient($GradientDegrees, $GradientShadow, $GradientStart); /* Standard syntax */
  height: 100vh;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

@mixin hover-darken-bg($bg-color) {
  background: $bg-color;
  border-color: $bg-color;
  &:hover, &:focus, &:active {
    background: darken($bg-color, 10%);
    border-color: darken($bg-color, 10%);
    outline: 0;
    outline: none;
  }
}

$SHGreen: #6B8C56;
$SHBeige: #E2E7DD;

.twitter-btn {
  @include hover-darken-bg(#4baaf4);
  color: white !important;
}

.facebook-btn {
  @include hover-darken-bg(#4b64a6);
  color: white !important;
}

.form-container {
  
  text-align: left;
  display: inline-block;
  
  .btn.dropdown-toggle {
    background: white;
    color: $SHBlue;
    text-transform: none;
    height: 44px;
  }

  .sh-form-control {
    height: 44px;
    width: 100%;
    border-radius: 4px;
    border-width: 0px;
  }
  
  .dropdown {
    width: 100%;
    border-radius: 4px;
  }
  .dropdown-menu {
    max-height: 300%;
    overflow-y: visible;
    background-color: #E2E7DD;
  }
  .dropdown-menu::-webkit-scrollbar {
    @include hover-darken-bg(#E2E7DD);
    border-radius: 4px;
    width: 7px;
  }
  .dropdown-menu::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid #E2E7DD;
    border-radius: 4px;
  }

  
  .btn {
    width: 100%;
    border-radius: 4px;
  }
  
  .field-container {
    margin-top: 15px;
  }
}

#send-text-btn {
  margin-top: 30px;
  min-width: 40px;
  width: 205px;
}

.sh-btn {
  position: relative;
  border-width: 0px;
  border-radius: 4px;
  padding: 5px;
  padding-left: 9px;
  padding-right: 9px;
  font-family: Karla;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 1.5px;
  
  .btn-icon {
    float: left;
    margin-left: 16px;
    margin-top: 4px;
  }
}

.onboarding-btn {
  @include hover-darken-bg($SHBeige);
  color: $SHGreen;
}

#sh-auth-logo {
  color: white;
  max-width: 238px;
  margin-top: 150px;
}

.onboarding-text {
  color: #FAF8F5;
  font-family: Karla;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
}

.onboarding-text {
  color: #FAF8F5;
  font-family: Karla;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
}

.onboarding-error-container {
  margin-bottom: 10px;
}

.onboarding-error {
  color: #D8BB43;
  font-family: Karla;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
}

.terms-footer {
  text-align: center;
  margin-top: 30px;
  width: 100%;
  
  div {
    max-width: 470px;
    word-wrap: break-word;
    display: inline-block;
  }
}

.onboarding-subtitle {
  text-align: center;
  width: 100%;
}

#mission-statement {
  color: #FAF8F5;
  width: 100%;
  font-family: Source Serif Pro;
  font-weight: normal;
  text-align: center;
  font-size: 14px;
  line-height: 23px;
  margin-top: 36px;
  margin-bottom: 23px;
  letter-spacing: 1px;
}

#auth-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 280px;
  display: block;
  overflow: auto;
  position: fixed;
  z-index: auto;
  
  .sh-btn {
    min-height: 57px;
    text-transform: uppercase;
    margin-bottom: 25px;
  }

  .full-width {
    min-width: 280px;
  }
  
  .field-container {
    margin-top: 40px;
    width: 250px;
  }

  .onboarding-text {
    li {
      a{
        color: #6B8C56;
        text-decoration: underline;
      }
      &:hover, &:focus {
        border-bottom-width: 0px;
        color: white;
        cursor:pointer;
        a span{
          color: white !important;
        }
      } 
    }
  }
}

#come-back-soon {
  display: inline-block;
  max-width: 650px;
  text-align: center;
}

#come-back-header {
  color: #FAF8F5;
  font-family: Karla;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  margin-top: 25px;
  margin-bottom: 35px;
}

#sync-health-link {
  margin-top: 30px;
}

#key-request-modal {
  
  .modal-body {
    text-align: center;
    padding: 50px;
    padding-bottom: 60px;
    
    h1 {
      font-family: Karla;
      font-size: 20pt;
    }
  }
  
  #pin {
    font-family: Karla;
    font-size: 54pt;
    color: $SHPurple;
  }
  
  #key-request-body {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 318px;
    display: inline-block;
  }
}
