@import "@karla/karla-react-theme/sass/karla.scss";

@import "fonts/lato/lato";
@import "fonts/fontello/fontello";
@import "fonts/climacons/climacons";
@import "fonts/devicon/devicon";
@import "fonts/dripicons/dripicons";
@import "fonts/feather/feather";
@import "fonts/flatline/flatline";
@import "fonts/mfizz/mfizz";
@import "fonts/nargela/nargela";
@import "fonts/glyphicons/glyphicons";
@import "fonts/ikons/ikons";
@import "fonts/outlined/outlined";
@import "fonts/pixelvicon/pixelvicon";
@import "fonts/Simple-Line-Icons/Simple-Line-Icons";
@import "fonts/Stroke-Gap-Icons/Stroke-Gap-Icons";

/* Component styles */
@import "components/sidebar";
@import "components/header";
@import "components/body";

/* Page styles */
@import "pages/pages";

.float-left {
  float: left;
}

.float-right {
  float: right;
}

body {
  padding: 0px !important;
}

.dropdown-menu {
  overflow-y: scroll;
  max-height: 400px;
  overflow: auto !important;
}
