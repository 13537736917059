#client-dashboard-tabs {

  .nav-tabs {

    li {

      margin-bottom: 0px;
      height: 60px;

      a {

        width: 150px;
        height: 100%;

        padding-top: 18px;
        padding-left: 0px;
        padding-right: 0px;

        font-size: 15px;
        text-align: center;
        text-transform: uppercase;

        border-radius: 0px;
        border-top: 0px;
        border-right: 1px;

        &:focus {
          outline: none;
        }
      }

      a[aria-selected="false"] {
        font-family: Karla;
        color: #A09C90;
        background-color: white;
      }

      a[aria-selected="true"] {
        color: $SHPurple;
        font-family: Karla;
        font-weight: bold;
        background-color: #FAF8F5;
      }
    }

    li:first-of-type {
      margin-left: 0px;
    }
  }

  .tab-content {
    border: 0px;
  }
}


.client-panel-header {
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;
}

#clinical {

  min-height: 420px;
  width: 100%;

  #note {
    min-width: 300px;
    min-height: 296px;
  }

  label, .note-title {
    color: $SHPurple;
  }

  .note-title{
    padding-top: 10px;
    margin: 0 0 4.5px;
    font-weight: bold;
  }

  .padding{
    padding: 10px 20px;
  }

  button {
    background: $SHPurple;
    color: white;
    border: 1px solid #D5D6D7;
    border-radius: 3px;
  }

  .pre-scrollable {
    height: 340px;
    width: 95%;
    overflow-y: scroll;
  }

  pre{
    background-color: white;
  }

  .time-stamp{
    color: lightgray;
  }


  .whendoLink {
    padding: 20px;
    font-size: 20px;
    color: $SHPurple;
  }

}

#client-tables{
  margin-top: 20px;

  p {
    font-family: Source Serif Pro;
    font-weight: regular;
    color: $SHPurple;
    font-size: 16px;
    padding: 15px 0px 0px 20px;
    display: inline;

  }

  td, th {
    font-family: Karla;
    font-weight: 200;
    color: $SHDarkGray;
    padding-left: 20px;
  }

  th{
    color: $SHMediumGray;
    border-bottom: 0px;
  }

  .light-line{
    border-bottom: solid #EDE9DF thin;
    margin: 40px 20px;
  }

  a.links {
    color: $SHPurple;
    display: inline;
    float: right;
    margin-right: 20px;
    font-size: 12px;
    font-family: Source Serif Pro;
  }

}

.notes-list {
  overflow: scroll;
  overflow-x: hidden;
  font-family: Karla;
  font-size: 10pt;
  height: 298px;
  margin-bottom: 20px;
  margin-top: 2px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}

#assign-modal{

  #bg-nested-dropdown, #bg-nested-dropdown:focus, #bg-nested-dropdown:hover, .dropdown-menu > li > a{
    min-width:350px;
    border-color: #D5D6D7;
    color: #26284B;
    font-family: Karla;
    font-weight: 200;
  }

  .form-conrol, button.active.radio-btns.radio-btns-default{
    color: #26284B;
  }

  .modal-title-error{
    color: rgba(192, 94, 98, 1.0);
    padding: 7px 0px;
    font-family: Karla;
  }

  .radio-btns{
    background-color: white;
    border: none;
    display:block;
  }

  .modal-header .close {
    margin-top: -40px;
    opacity: 1;
    color: white;
    font-weight: 100;
    margin-right: -33px;
    font-size: 43px;
  }

  .modal-header{
    height: 10px;
    padding: 0px;
    border-bottom: 0px;
    font-family: Karla;
  }

  .modal-title{
    color: #666982;
    padding: 7px 0px;
    font-family: Karla;
  }

  .radio{
    float: left;
    margin-right: 20px;
  }

  input[type='text']{
    height: 44px;
  }

  .modal-input {
    resize: none;
    font-family: Karla;
    border-radius: 5px;
    border: 1px solid #D5D6D7;
  }

  #open-ended-input {
    height: 200px;
  }

  input[type="radio"]{
    margin-right: 20px;
  }

  button.radio-btns.radio-btns-default, button.active.radio-btns.radio-btns-default{
    background-color: white;
  }

  button.assignButton.btn.btn-default{
    background-color: $SHPurple;
    color: #FFFFFF;
    float: right;
    border-width: 0px;
    font-family: Karla;
    font-weight: 200;
    font-size: 12pt;
    height: 44px;
    border-radius: 5px;
    margin-top: 40px;
    line-height: 12px;
  }

  .row {
    margin-left: 0px;
  }

  .border-left {
    border-left: solid #E5DFCF thin;
  }

  .vertical-line{
    border-left: solid #E5DFCF thin;
    height: 385px;
    padding: 0px;
  }

//input radio button color
  input[type='radio'] {
    -webkit-appearance:none;
    width:20px;
    height:20px;
    border-radius:50%;
    outline:none;
    background-color: #EDE9DF;
    font-family: Karla;
  }

  input[type='radio']:hover {
    box-shadow:0 0 5px 0px orange inset;
  }

  input[type='radio']:before {
    content:'';
    display:block;
    width:60%;
    height:60%;
    margin: 20% auto;
    border-radius:50%;
  }
  input[type='radio']:checked:before {
    background:#6B8C56;
  }

  @media only screen and (max-width : 1155px){
    #bg-nested-dropdown, .dropdown-menu > li > a, #bg-nested-dropdown:focus, #bg-nested-dropdown:hover{
      min-width:150px;
    }
  }

  @media only screen and (max-width : 965px){
    .vertical-line{
      display:none;
      height: 0px;
    }
    button.assignButton.btn.btn-default{
      margin:10px;
      float: left;
    }
    .links{
      float: none;
    }
    .modal-header .close{
      margin-right: 0px;
    }
  }
}
