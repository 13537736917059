#admin-list {
  margin-top: 20px;
}

.admin-dropdown-item {
  margin: 8px;
     position: relative;
    z-index: 10000;
}

.delete-x-container {
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}
.org-name {
  div {
    display: flex;
  }
}
#upload-users-btn {
  background: $SHPurple;
  color: white;
  width: 393px;
  height: 78px;
  margin: 45px;
  border-radius: 4px;
  text-transform: none;
}
