.survey-modal {

  word-wrap: break-word;

  .modal-content {
    padding: 15px;
  }

  #survey-title {
    font-family: Source Serif Pro;
    font-weight: normal;
    font-size: 17pt;
    color: $SHPurple;
  }

  .survey-question {
    color: $SHGreen;
    font-family: Karla;
    font-weight: bold;
    font-size: 13pt;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .survey-answer {
    font-family: Karla;
    font-weight: normal;
    color: $SHNavyBlue;
    font-size: 11pt;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .assign-form-row {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }

  .assign-form-column {
    width: 50%;
    float: left;

    .btn.dropdown-toggle {
      height: 44px;
      width: 250px;
      border-width: 1px;
      border-color: #dddddd;

      .caret {
        float: right;
        margin-top: 9px;
      }
    }

    &:last-of-type {
      min-width: 320px;
    }
  }

}
